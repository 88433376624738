import React from 'react';
import Layout from '~components/layouts/Default';
import Container from '~components/common/Container';
import H4 from '~components/typography/H4';
import H2 from '~components/typography/H2';
import H1 from '~components/typography/H1';
import P from '~components/typography/P';
import Button from '~components/common/Button';
import SubTitle from '~components/typography/Subtitle';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="E-Mail Bestätigt" desc="" noIndex>
      <Container>
        <div className="mx-auto mb-8 max-w-2xl text-center">
          <SubTitle className="mb-2">Anmeldung erfolgreich</SubTitle>
          <H2 className="mb-4">Deine Anmeldung zum Newsletter war erfolgreich!</H2>
          <P>Vielen Dank für Deine Anmeldung!</P>
        </div>
      </Container>
    </Layout>
  );
}
